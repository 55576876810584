import { QuestDisplayType } from '@vi/quest';
import { Environment } from './environment.model';

export const environment: Environment = {
    env: 'local',
    production: true,
    version: '0.0.0',
    instana: true,
    apiUrl: 'https://api-integration.viessmann.com/',
    accountUrl:
        'https://account-integration.viessmann.com/personal-data?redirect=https://admin-profi-integration.viessmann.com&originApp=Admin Profi',
    app: {
        defaultLanguage: 'de',
        supportedLanguages: [{ locale: 'de-DE', flag: 'de' }],
    },
    auth: {
        appId: 'admin-profi',
        baseUrl: 'https://api-integration.viessmann.com',
        registerBaseUrl: 'https://account-integration.viessmann.com',
    },
    http: {
        baseUrl: 'https://api-integration.viessmann.com/backend/admin-profi/v1/',
        translationBaseUrl: 'https://api-integration.viessmann.com/translations/v2/',
        legal: 'https://api-integration.viessmann.com/legal/v3/',
        users: 'https://api-integration.viessmann.com/users/v1/',
        company: 'https://api-integration.viessmann.com/company/v3/',
    },
    configit: {
        baseUrl: 'https://api-integration.viessmann.com/backend/configuration/v1/configit',
        imageUrl: 'https://api-integration.viessmann.com/backend/configuration/v1/configit/images',
        languages: ['de'],
        salesAreaName: 'Germany',
    },
    quest: {
        context: 'quest',
        adminProfiModel: 'AdminProfi',
        ePlanModel: 'EPlan',
        configurationDisplayType: QuestDisplayType.tabbed,
    },
    file: {
        uploadUrl: 'https://api-integration.viessmann.com/backend/admin-profi/v1/configurations/{id}/attachments',
        downloadUrl:
            'https://api-integration.viessmann.com/backend/admin-profi/v1/configurations/{id}/attachments/{attachmentId}',
        configurationDownloadUrl:
            'https://api-integration.viessmann.com/backend/admin-profi/v1/configurations/{id}/pdf',
        configurationDownload: true,
    },
    availableCountries: ['DE'],
    statusPage: 'https://api-integration.viessmann.com/status/v1/apps/dcqw0ypbq67n/summary',
};
